<template>
  <div class="wrapper">
    <div>
      <div class="block-banner padding-style-block">

      </div>
    </div>
    <div class="padding-style-block">
      <h1 class="block-title">РЕГИСТРИРУЙТЕСЬ</h1>
      <h3 class="block-title">В РОЗЫГРЫШЕ НАТЯЖНОГО ПОТОЛКА 10 кв.м С УСТАНОВКОЙ!</h3>
      <h3 class="block-title block-title-padd">{{ `[Участвуют: ${String(cityList).replaceAll(',', ', ')}]` }}</h3>
    </div>
    <div class="block-line padding-style">
      <span>
        <svg data-mode="fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>
      </span>
    </div>
    <div class="padding-style-block block-text">
      <p>Все участники розыгрыша получают от нас СЕРТИФИКАТ на 100 РУБЛЕЙ, которым можно воспользоваться уже сейчас!</p>
      <p class="block-title-padd">Внимательно заполняйте контактные данные для связи с победителем и вручения подарочного сертификата.</p>
    </div>
    <div class="block-line padding-style">
      <span>
        <svg data-mode="fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>
      </span>
    </div>
    <div class="padding-style-block">
      <form @submit.prevent="submitForm">
        <div class="block-input-name">
          <label class="block-input-name__label">Ваше имя:</label>
          <input maxlength="30" type="text" class="block-input-name__input" v-model="name"/>
        </div>
        <div class="block-input-name">
          <label class="block-input-name__label">Когда планируете установку потолка?
            <sup class="required">*</sup>
          </label>
          <div class="block-radio">
            <label>
              <input type="radio" required  name="answer1" v-model="answer1" value="Уже сейчас"/>
              Уже сейчас
            </label>
            <label>
              <input type="radio"  v-model="answer1" name="answer1"  value="В течение месяца"/>
              В течение месяца
            </label>
            <label>
              <input type="radio"  v-model="answer1"  name="answer1" value="Через 2-3 месяца"/>
              Через 2-3 месяца 
            </label>
            <label>
              <input type="radio"  v-model="answer1" name="answer1" value="Ещё не знаю"/>
              Ещё не знаю
            </label>
          </div>
        </div>
        <div class="block-input-name">
          <label class="block-input-name__label">Выберите ваш населённый пункт:
            <sup class="required">*</sup>
          </label>
          <div class="block-radio">
            <label v-for="city of cityList" :key="city">
              <input type="radio"  v-model="answer2" :id="city"  name="answer2" :value="city"/>
              {{ city }}
            </label>

            <label>
              <input type="radio"  v-model="answer2"  name="answer2" value="Другое"/>
              Другое
            </label>
          </div>
        </div>
        <div class="block-input-name">
          <label class="block-input-name__label">Готовы записаться на бесплатный замер прямо сейчас?
            <sup class="required">*</sup>
          </label>
          <div class="block-radio">
            <label>
              <input type="radio" v-model="answer3" name="answer3" required value="Да"/>
              Да
            </label>
            <label>
              <input type="radio"  v-model="answer3" name="answer3" value="Нет"/>
              Нет
            </label>
            <label>
              <input type="radio"  v-model="answer3" name="answer3" value="Сначала хочу консультацию"/>
              Сначала хочу консультацию 
            </label>
          </div>
        </div>
        <div class="block-input-name">
          <label class="block-input-name__label">За каким номером закрепить ваш сертификат?
            <sup class="required">*</sup>
          </label>
          <input 
            v-maska 
            data-maska="+375 (##) ###-##-##" 
            v-model="answer4" 
            class="block-input-name__input"
            placeholder="+375 (XX) XXX-XX-XX"
            required>
        </div>
        <div>
          <button :disabled="isSubmitBtnDisabled" type="submit">ПРИНЯТЬ УЧАСТИЕ!</button>
        </div>
        <div class="block-line padding-style">
          <span>
            <svg data-mode="stroke" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" stroke-width="1" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="M10.5 20.4l-6.9-6.9c-.781-.781-.781-2.219 0-3l6.9-6.9c.781-.781 2.219-.781 3 0l6.9 6.9c.781.781.781 2.219 0 3l-6.9 6.9c-.781.781-2.219.781-3 0z"></path></svg>
          </span>
        </div>
      </form>
      <div class="personal-terms-block">
        <a href="/personal-terms" class="personal-terms-link">Политика обработки персональных данных</a>
      </div>
      <!-- <div>
        <p class="title-timer">До конца розыгрыша осталось:</p>
        <div class="block-digit">
          <div class="block-digit__text">
            <p>Дни</p>
            <p>Часы</p>
            <p>Минуты</p>
            <p>Секунды</p>
          </div>
          <div class="block-digit__time">
            <div v-for="(digit, index) in digits" :key="index" class="digit">
              <div class="digit-item">{{ digit }}</div>
              <div v-if="addCircle(index)" class="block-points">
                <div class="circle"></div>
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { vMaska } from "maska";
import { useRouter } from 'vue-router';
import axios from 'axios';


let totalSeconds = 18 * 86400 + 15 * 3600 + 4 * 60 + 3;
let intervalId;

let cityList = window.potolkuemSettings.cityList;

const digits = ref([]);

const answer1 = ref('');
const answer2 = ref('');
const answer3 = ref('');
const answer4 = ref('');
const name = ref('');

const days = ref(18);
const hours = ref(15);
const minutes = ref(4);
const seconds = ref(3);

const router = useRouter();

let isSubmitBtnDisabled = false;

const submitForm = async function() {
  isSubmitBtnDisabled = true;

  let userName = `Имя: ${name.value ? name.value : 'не указано'}`;
  let firstQuestion = `%0AКогда планируете установку потолка: ${answer1.value}`;
  let secondQuestion = `%0AВыберите ваш населённый пункт: ${answer2.value}`;
  let thirdQuestion = `%0AГотовы записаться на бесплатный замер прямо сейчас: ${answer3.value}`;
  let phoneNumber = `%0AНомер телефона: ${answer4.value}`;

  await axios.post(`https://api.telegram.org/bot6944590493:AAFbJkPwnUAhQ4E8nN7YwYDNRgCKTMfSzD0/sendMessage?chat_id=-1001994751084&text=${userName}${firstQuestion}${secondQuestion}${thirdQuestion}${phoneNumber}`);

  isSubmitBtnDisabled = false;
  
    router.push({
      path: '/offer',
    });
}

const updateTimer = () => {
  if (totalSeconds <= 0) {
    totalSeconds = 18 * 86400 + 15 * 3600 + 4 * 60 + 3;
  } else {
    totalSeconds--;
  }

  days.value = Math.floor(totalSeconds / 86400);
  let remainderSeconds = totalSeconds % 86400;

  hours.value = Math.floor(remainderSeconds / 3600);
  remainderSeconds = remainderSeconds % 3600;

  minutes.value = Math.floor(remainderSeconds / 60);
  seconds.value = remainderSeconds % 60;

    digits.value = [
    ('0' + days.value).slice(-2),
    ('0' + hours.value).slice(-2),
    ('0' + minutes.value).slice(-2),
    ('0' + seconds.value).slice(-2)
  ];

  digits.value = digits.value.reduce((acc, curr) => {
    acc.push(...curr.split(''));
    return acc;
  }, []);
}

const addCircle = (index) => {
        return (index + 1) % 2 === 0 && (index + 1) !== digits.value.length;
      }

onMounted(() => {
  if (localStorage.getItem('totalSeconds')) {
    totalSeconds = parseInt(localStorage.getItem('totalSeconds'));
  }

  intervalId = setInterval(() => {
    updateTimer();
    localStorage.setItem('totalSeconds', totalSeconds.toString());
  }, 1000);
});

onUnmounted(() => {
  clearInterval(intervalId);
});
</script>

<style lang="scss" scoped>
.circle {
  width: 10px;
  height: 10px;
  background: #222;
  border-radius: 50%;
  display: inline-block;
}
.wrapper {
  max-width: 820px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-right: 2%;
  padding-left: 2%;
}

.padding-style-block {
  padding: 0.5rem 0;
}

.block-banner {
  background: url('../../public/banner.jpg') 0px 0px / 100% no-repeat;
  width: 100%;
  padding: 25% 0 0.5rem 0;
}

form {
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.block-title {
  font-weight: 400;
  font-size: 17px;
  line-height: 24.65px;
  color: rgb(87, 50, 34);
  text-align: center;
}

.block-title-padd {
  padding-top: 20px;
}

.block-line {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 30px;
}

span:after, span:before {
  content: '';
  position: absolute;
  top: 50%;
  width: 10%;
  height: 1px;
  opacity: .3;
  background-color: #573222;
}

span:before {
  left: 0;
  width: 45%;
  transform: translate(0,0);
}

span:after {
  right: 0;
  width: 45%;
  transform: translate(0,0);
}

svg {
    width: 17px;
    height: 17px;
    top: 2px;
    position: relative;
}

.block-text {
  font-size: 14px;
  line-height: 20.3px;
  color: rgb(87, 50, 34);
  text-align: center;
}

.block-input-name {
  display: flex;
  flex-direction: column;

  &__label {
    font-family: 'Open Sans', sans-serif;
    font-size: 17px;
    line-height: 24.65px;
    margin-bottom: 8.5px;
    color: rgb(87, 50, 34);
  }

  &__input {
    padding: 14px;
    margin-bottom: 14px;
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    transition: border-color 0.3s;

    &:focus {
      outline: 0;
      border-color: #e2d4ce;
      box-shadow: 0 0 0 1px #e2d4ce;
    }
  }
}

.required {
  color: red;
  margin-left: 0.35em;
  line-height: 1;
  font-weight: 700;
}

.block-radio {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.block-radio label {
  display: flex;
  align-items: center;
  font-size: 17px;
  font-family: 'Open Sans', sans-serif;
  color: rgb(0, 0, 0);
  width: max-content;
  cursor: pointer;
}

input[type=radio] {
  all: unset;
  width: 22px;
  height: 22px;
  margin-right: 0.5rem;
  line-height: 24.65px;
  cursor: pointer;
  position: relative;
}

input[type=radio]:before {
  border-radius: 28px;
  background: #fff;
  border-color: #00000020;
  border: 1px solid #d9d9d9;
  display: block;
  width: 100%;
  height: 100%;
  content: '';
  transition: background-color 20ms ease-in-out;
}

input[type=radio]:checked:before {
  background: #e2d4ce;;
  border-color: #e2d4ce;
}

input[type=radio]:after { 
  content: '';
  background: #000;
  width: 100%;
  height: 100%;
  display: block;
  top: 1px;
  left: 1px;
  position: absolute;
  transform: scale(0);
  border-radius: 28px;
  opacity: 0;
  transition: transform .3s ease;
}

input:checked:after {
  opacity: 1;
  transform: scale(.4);
}

button {
  all: unset;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ff6b6b;
  color: white;
  border-radius: 10px;
  height: 60px;
  padding: 8px;
  box-sizing: border-box;
  font-size: 16px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 700;
  cursor: pointer;

  &:disabled {
      background-color: red;
      cursor: not-allowed;
    }
}

.block-digit {
  // display: flex;
  width: 100%;

  &__text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 10px;

    p {
      width: 50px;
      font-size: 12px;
      color: rgb(87, 50, 34);
      font-family: 'Open Sans', sans-serif;
    }
  }

  &__time {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.digit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  // border-radius: 5px;
  // width: 80px;
  // background: #222;
  font-size: 80px;
  font-weight: 700;
  color: rgb(204, 204, 204);
  line-height: 123.077px;
  transition: all 0.2s ease;
}

.digit-item {
  width: 80px;
  background: #222;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.2s ease;
}

.block-points {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-left: 10px;
}

.title-timer {
  font-family:  Alice, cursive;
  font-size: 20px;
  text-align: center;
  line-height: 29px;
  color: rgb(87, 50, 34);
  padding: 10px 0 20px;
}

@media (max-width: 786px) {
  .digit {
    font-size: 40px;
    line-height: 76.077px;
  }

  .digit-item {
    width: 50px;
  }

  .block-digit__time {
    justify-content: center;
    gap: 5px;
  }

  .block-points {
    padding-left: 5px;
  }

  .block-digit__text {
    justify-content: center;
    gap: 88px;
  }

  .block-digit__text p {
    width: 33px;
  }
}

@media (max-width: 786px) { 
  .digit {
    font-size: 30px;
    line-height: 56.077px;
  }

  .digit-item {
    width: 34px;
  }

  .block-digit__text {
    justify-content: center;
    gap: 57px;
  }
}

.personal-terms-block {
  padding: 20px 0 15px 0;
}

.personal-terms-link {
  font-family: "Alice", serif;
  font-size: 14px;
  line-height: 20.3px;
  color: rgb(87, 50, 34);
  text-decoration: none;
}
</style>