import { createRouter, createWebHistory } from 'vue-router'
import index from '../views/index.vue'
import offer from '../views/offer.vue'
import personalTerms from '../views/personal-terms.vue'


const routes = [
  {
    path: '/',
    name: 'index',
    component: index
  },
  {
    path: '/offer',
    name: 'offer',
    component: offer
  },
  {
    path: '/personal-terms',
    name: 'personal-terms',
    component: personalTerms
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
