<template>
  <div>
    <h1>Согласие на действия с персональными данными</h1>
    <p>
      1. Настоящее Согласие Пользователя (далее – настоящее Согласие) является неотъемлемой частью Соглашения о порядке 
      использования сайта «potolkuem.by», расположенный по адресу http://potolkuem.by (далее – Соглашение) и дается сайту 
      исключительно в целях предоставления последним Пользователю возможности учавствовать в розыгрыше, 
      проводимом администратором сайта.
    </p>
    <p>
      2. Термины и их определения в настоящем Согласии используются в терминах и их определениях, приведенных в Соглашении, 
      а также: термин «персональные данные» применяется в определении и значении, установленном ст. 8 Закона Республики 
      Беларусь от 21.07.2008 № 418-З «О регистре населения» в значении и определении термина «основные персональные данные»; 
      термин «согласие» означает согласие Пользователя на осуществление действий с информацией, касающейся Пользователя 
      и относящейся к коммерческой или иной охраняемой законом тайне для участия в розыгрыше, проводимом администрацией 
      сайта, предоставляемой Пользователем посредством сети интернет; термин «действия» означает - сбор, обработку, 
      хранение, запись, систематизацию, накопление, уточнение (обновление, изменение), извлечение, использование, 
      удаление информации в информационных ресурсах (системах) Республики Беларусь, о персональных данных Пользователя, 
      с соблюдением требований, определенных законодательством об информации, информатизации и защите информации.
    </p>
    <p>
      3. Подтверждение Согласия Пользователя осуществляется в процессе регистрации Пользователя посредством нажатия 
      Пользователем кнопки "Я согласен с правилами участия в розыгрыше и передачей персональных данных", помещенной 
      под формой регистрации. Момент регистрации в розыгрыше означает подтверждение Пользователем своего согласия с 
      Согласием, а также согласие со всеми условиями Соглашения и безоговорочное их принятие.
    </p>
    <p>
      4. Принимая условия настоящего Согласия и Соглашения, Пользователь подтверждает, что при регистрации в розыгрыше  
      по адресу http://potolkuemby.online/ Пользователь указал достоверные персональные данные, даёт своё согласие 
      на осуществление действий со своими персональными данными, а также с информацией, касающейся Пользователя и 
      относящейся к коммерческой или иной охраняемой законом тайне, администраторам сайта «potolkuem.by» в соответствии 
      с законодательством Республики Беларусь с соблюдением требований, определенных законодательством об информации, 
      информатизации и защите информации.
    </p>
    <p>
      5. Целью получения сайтом «potolkuem.by» Согласия Пользователя является предоставление возможности Пользователю 
      учавствовать в розыгрыше сайта, путем регистрации учетной записи. Оператор сайта имеет право осуществлять действия 
      с персональными данными Пользователя, а также с информацией, касающейся Пользователя и относящейся к коммерческой 
      или иной охраняемой законом тайне, в течение срока действия розыгрыша.
    </p>
    <p>
      6. Настоящее Согласие действует с момента его предоставления Пользователем сайту «potolkuem.by» в порядке, 
      определенном п. 3 настоящего Согласия, и действует до завершения проведения розыгрыша.
    </p>
    <p>
      7. Сайт «potolkuem.by» не несет никакой ответственности за искажение и утечку персональных данных Пользователя, 
      информации, касающейся Пользователя и относящейся к коммерческой или иной охраняемой законом тайне, 
      по незащищённому каналу связи.
    </p>
  </div>
</template>


<style lang="scss" scoped>
div {
  max-width: 820px;
  margin: 0 auto;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 4%;
  padding-left: 4%;
}

h1 {
  font-family: "Open Sans", sans-serif;
  font-size: 17px;
  line-height: 24.65px;
  margin-bottom: 8.5px;
  color: rgb(87, 50, 34);
}

p {
  padding-bottom: 15px;
}
</style>